export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  innocard: {
    api:'https://rest.innocardloyalty.ch/LOIN',
    api_v2: 'https://pre.rest.innocardloyaltytest.ch/LOIN2'
    //api:'https://rest.iclytest.ch/LOIN',
    //api_v2: 'https://beta.api.innocard.varlik.com.br/LOIN2'
  }
};
